import * as React from "react";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import { Link, useParams } from "react-router-dom";
import { Ticket } from "../../tickets-api/generated-src";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import {
  Box,
  ColumnLayout,
  Container,
  Header,
} from "@amzn/awsui-components-react";
import {
  ongoingProactiveInsights,
  ongoingReactiveInsights,
  anomalyMetricsServices,
  totalMetricsServices,
} from "../commons/OngoingInsights";

export default function DataSourcesCards(props) {
  /*
       DataSourcesCards Component
    */

  const { ticketId } = useParams<{ ticketId: string }>();
  const rootCausesSet = new Set();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const allSourceItems: Ticket[] = props.data || [];

  const SOURCES_HAVING_INSIGHTS = ["APOLLO", "MCM", "WEBLABS", "SEV2"];

  const ongoingProactiveInsightsTotal = (dataSourceType: string) => {
    /*
        Returns the total number of on going proactive insights for a particular dataSource(APOLLO, MCM, WEBLAB, SEV2)
       :criteria to count total proactive insights: 1) adding all proactive insights in APOLLO/MCM/WEBLAB/SEV2 for primary and dependency services 2) adding proactive insights in LSE 3) total anomaly services analysed for a metric (THROTTLING,CPU_UTILISATION,DISK_USAGE,MEM_ACTIVE,TRAFFIC_SURGE,VIP_SPILLOVERS)
       :param dataSourcetype: APOLLO/MCM/WEBLAB/SEV2
       :rtype: integer
       */
    if (SOURCES_HAVING_INSIGHTS.includes(dataSourceType)) {
      const primaryServiceProactiveInsights = ongoingProactiveInsights(
        dataSourceType,
        "Primary Services",
        allSourceItems
      );
      const dependencyServiceProactiveInsights = ongoingProactiveInsights(
        dataSourceType,
        "Dependency Services",
        allSourceItems
      );
      return (
        primaryServiceProactiveInsights + dependencyServiceProactiveInsights
      );
    } else {
      if (dataSourceType === "LSE") {
        return ongoingProactiveInsights(dataSourceType, "LSE", allSourceItems);
      } else {
        return anomalyMetricsServices(dataSourceType, allSourceItems);
      }
    }
  };

  const ongoingReactiveInsightsTotal = (dataSourceType: string) => {
    /*
        Returns the total number of on going reactive insights for a particular dataSource(APOLLO, MCM, WEBLAB, SEV2, LSE, THROTTLING,CPU_UTILISATION,DISK_USAGE,MEM_ACTIVE,TRAFFIC_SURGE,VIP_SPILLOVERS )
        :criteria to count total reactive insights: 1) adding all reactive insights in APOLLO/MCM/WEBLAB/SEV2 for primary and dependency services 2) adding reactive insights in LSE 3) total services analysed for a metric (THROTTLING,CPU_UTILISATION,DISK_USAGE,MEM_ACTIVE,TRAFFIC_SURGE,VIP_SPILLOVERS)
        :param dataSourcetype: APOLLO/MCM/WEBLAB/SEV2
        :rtype: integer
        */
    if (SOURCES_HAVING_INSIGHTS.includes(dataSourceType)) {
      const primaryServiceReactiveInsights = ongoingReactiveInsights(
        dataSourceType,
        "Primary Services",
        allSourceItems
      );
      const dependencyServiceReactiveInsights = ongoingReactiveInsights(
        dataSourceType,
        "Dependency Services",
        allSourceItems
      );
      return primaryServiceReactiveInsights + dependencyServiceReactiveInsights;
    } else {
      if (dataSourceType === "LSE") {
        return ongoingReactiveInsights(dataSourceType, "LSE", allSourceItems);
      } else {
        return totalMetricsServices(dataSourceType, allSourceItems);
      }
    }
  };

  const filteredDataSources = allSourceItems.filter((item) => {
    /*
         Filtering the raw dataSources array for having unqiue dataSource and removing SERVICES_DATA,SERVICE_UNAVAILABILITY,TICKET_DETAILS
       */
    if ("sourceType" in item) {
      const sourceType = item.sourceType.split("-")[0];
      if (
        !rootCausesSet.has(sourceType) &&
        sourceType != "SERVICES_DATA" &&
        sourceType != "SERVICE_UNAVAILABILITY" &&
        sourceType != "TICKET_DETAILS"
      ) {
        item.sourceType = sourceType;
        rootCausesSet.add(sourceType);
        return true;
      } else {
        return false;
      }
    }
  });

  let totalProactiveInsights = {};

  filteredDataSources.forEach((item) => {
    const dataSourceType = item.sourceType;
    totalProactiveInsights[dataSourceType] =
      ongoingProactiveInsightsTotal(dataSourceType);
  });

  const totalPossibleRootCause = () => {
    /*
        Returns the total number of possible rootcauses which are in alarm state
       :condition to check if dataSource can be a possible rootcause: if for a dataSource the total number of ongoingproactiveinsights>0
       :rtype: integer
       */
    let count = 0;
    filteredDataSources.forEach((item) => {
      if ("sourceType" in item) {
        if (totalProactiveInsights[item.sourceType] > 0) {
          count += 1;
        }
      }
    });
    return count;
  };

  const totalRootCausesCount = totalPossibleRootCause();

  return (
    <>
      <Container header={<Header variant="h2">System Health Summary</Header>}>
        <ColumnLayout columns={3} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">
              Total resources analyzed last hour
            </Box>
            <div>
              <Header variant="h1">{rootCausesSet.size}</Header>
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Ongoing proactive insights</Box>
            <div>
              <Header variant="h1">{totalRootCausesCount}</Header>
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Ongoing reactive insights</Box>
            <div>
              <Header variant="h1">
                {rootCausesSet.size - totalRootCausesCount}
              </Header>
            </div>
          </div>
        </ColumnLayout>
      </Container>
      <br />
      {allSourceItems.length > 0 ? (
        <Cards
          id="Hse_RootCauses_cards"
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.sourceType}`,
            selectionGroupLabel: "Item selection",
          }}
          cardDefinition={{
            header: (e) => (
              <Link
                style={{ textDecoration: "none" }}
                to={`/${ticketId}/${e.sourceType}`}
              >
                <span style={{ fontWeight: "bolder" }}>
                  {totalProactiveInsights[e.sourceType] == 0 ? (
                    <span style={{ color: "green" }}>{e.sourceType}</span>
                  ) : (
                    <span style={{ color: "red" }}>{e.sourceType}</span>
                  )}
                  <span style={{ float: "right" }}>
                    {totalProactiveInsights[e.sourceType] == 0 ? (
                      <Icon
                        name="status-positive"
                        size="small"
                        variant="success"
                      />
                    ) : (
                      <Icon
                        name="status-warning"
                        size="small"
                        variant="warning"
                      />
                    )}
                  </span>
                </span>
              </Link>
            ),

            sections: [
              {
                id: "proactiveInsights",
                header: "Ongoing proactive insights",
                content: (e) => (
                  <span style={{ color: "#2565AE" }}>
                    {totalProactiveInsights[e.sourceType]}
                  </span>
                ),
              },
              {
                id: "reactiveInsights",
                header: "Ongoing reactive insights",
                content: (e) => (
                  <span style={{ color: "#2565AE" }}>
                    {ongoingReactiveInsightsTotal(e.sourceType)}
                  </span>
                ),
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 400, cards: 3 }]}
          items={filteredDataSources}
          loadingText="Loading resources"
        />
      ) : (
        <span style={{ marginLeft: "auto", marginRight: "auto" }}>
          <Spinner size="large" />
        </span>
      )}
    </>
  );
}
