import React from "react";
import Navigation from "../SideNavigation";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Header from "@amzn/awsui-components-react/polaris/header";
import TicketsTable from "./TicketsTable";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

export default function TicketsListViewHome() {
  /*
       Component to render datetime filter and tickets list view table
   */
  const startdate = "start";
  const enddate = "end";

  return (
    <AppLayout
      content={
        <ContentLayout
          header={<Header variant="h1">Alexa HSE Insights</Header>}
        >
          <SpaceBetween size="l">
            <>
              <div>
                <TicketsTable StartDate={startdate} EndDate={enddate} />
              </div>
            </>
          </SpaceBetween>
        </ContentLayout>
      }
      navigation={<Navigation />}
    />
  );
}
