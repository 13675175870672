const TOOLTIP_MAX_LENGTH = 50;


/**
 * Function to get string value from event properties.
 * @param properties The map of event properties.
 * @param key The key to fetch str value for.
 * @param withValidation To raise an error if key does not exist or has an invalid value.
 * @throws TypeError If key does not exist or has an invalid value.
 */
export function getStrFromEventsProperties(
    properties: { [key: string]: any[] }, key: string, withValidation: boolean = false): string {
    if (properties[key]
        && properties[key] instanceof Array
        && properties[key].length > 0) {
        return properties[key][0].toString();
    }
    if (withValidation)
        throw TypeError(`${key} does not exist in properties, or has a non-string value.`);
    return "";
}


/**
 * Function to process weblab treatments from event specific properties.
 * @param properties The map of event properties.
 * @throws Error If there is invalid data present for treatments.
 */
export function getWeblabTreatmentsFromProperties(properties: { [key: string]: any[] }): string {
    const controlExposure: number = parseFloat(
        getStrFromEventsProperties(properties, "C")
    );
    const treatments: { [key: string]: number } = {
        "C": controlExposure
    };
    let i: number = 1;
    while (`T${i}` in properties) {
        treatments[`T${i}`] = parseFloat(
            getStrFromEventsProperties(properties, `T${i}`)
        );
        i++;
    }

    // Reading additional treatments if provided in properties
    if (properties["Additional Treatments"]) {
        for (const treatmentInfo of properties["Additional Treatments"]) {
            const treatmentInfoSplits: string[] = treatmentInfo.trim().split(":");
            const treatment = treatmentInfoSplits[0];
            treatments[treatment] = parseFloat(treatmentInfoSplits[1]);
        }
    }

    // Exposure can be given in percentages or ratios. Converting to percentages for both cases.
    let totalExposure: number = parseFloat(
        getStrFromEventsProperties(properties, "Exposure Percentage"));
    let totalRatioPoints = Object.values(treatments)
        .reduce((previous, current) => previous + current, 0);
    Object.keys(treatments)
        .forEach(treatment => {
            treatments[treatment] = ((totalExposure * treatments[treatment]) / totalRatioPoints)
        });

    return Object.keys(treatments).map(t => `${t}: ${treatments[t]}%`).join(", ");
}


/**
 * Function to get Millis from seconds time.
 * @param seconds The current time in seconds.
 * @return The current time in millis.
 */
export function getMillisFromSeconds(seconds: number) {
    return seconds * 1000;
}


/**
 * Function to get Seconds from millis time.
 * @param millis The current time in millis.
 * @return The current time in seconds.
 */
export function getSecondsFromMillis(millis: number) {
    return millis / 1000;
}

/**
 * Function to create tooltip content from given map of keys and values.
 * @param items The map keys and values.
 * @return The HTML content string for given map.
 */
export function createTooltip(items: { [key: string]: string | number }): string {
    return Object.keys(items)
        .map(key => {
            let value: string = items[key].toString()
            if (value.length > TOOLTIP_MAX_LENGTH) {
                value = value.substring(0, TOOLTIP_MAX_LENGTH) + "..."
            }
            return `<p>${key}: ${value}</p>`
        })
        .join("");
}
