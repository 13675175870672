import * as React from "react";
import { useEffect, useState } from "react";
import TicketsApiFactory from "../../../tickets-api/TicketsApiFactory";
import { useParams } from "react-router-dom";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Table from "@amzn/awsui-components-react/polaris/table";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Navigation from "../../SideNavigation";

export default function MetricDetails() {
  const [allItems, setAllItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { dataSource } = useParams<{ dataSource: string }>();
  let { dataSourceType } = useParams<{ dataSourceType: string }>();

  if (dataSourceType === undefined) {
    dataSourceType = "Metrics";
  }

  useEffect(() => {
    (async () => {
      setAllItems(
        (
          await TicketsApi.showDataSourceDetails(
            ticketId,
            dataSource,
            dataSourceType
          )
        ).data
      );
    })();
  }, [ticketId]);

  const tableItems = allItems || [];

  const formatIgraphLink = (igraphLink: string) => {
    /*
       Function to add additional parameters to the igraphLink for getting image
     */
    if (igraphLink.includes("Pattern1")) {
      const additionalParameters = "&OutputFormat=PNG&Action=GetGraph";
      var igraphLinkModified = igraphLink.replace("igraph", "mws");
      igraphLinkModified = igraphLinkModified.concat(additionalParameters);
      return igraphLinkModified;
    } else {
      const additionalParameters =
        "&Action=GetGraph&StartTime1=-PT3H&EndTime1=-PT0M" +
        "&actionSource=iGraph&WidthInPixels=1288" +
        "&HeightInPixels=296&NoRedirect=1&forceRefresh=1671937712790";
      var igraphLinkModified = igraphLink.replace("igraph", "mws/");
      igraphLinkModified = igraphLinkModified.concat(additionalParameters);
      return igraphLinkModified;
    }
  };

  const getMetricsDetails = () => {
    /*
      Function to retrieve the required metrics details
    */
    const metricsItems: any[] = tableItems[0]["compressedData"];
    const filteredArray: any[] = [];
    for (const key of Object.keys(metricsItems)) {
      if (
        metricsItems[key]["igraph_link"] !== undefined &&
        "analysis_result" in metricsItems[key]
      ) {
        filteredArray.push({
          service_name: key,
          anomaly_start_time:
            metricsItems[key]["analysis_result"]["abnormality_start_time"] ===
              "" ||
            metricsItems[key]["analysis_result"]["abnormality_start_time"] ===
              null
              ? "-"
              : metricsItems[key]["analysis_result"]["abnormality_start_time"],
          igraph_link_original: metricsItems[key]["igraph_link"],
          igraph_link_modified: formatIgraphLink(
            metricsItems[key]["igraph_link"]
          ),
          breach_count: metricsItems[key]["analysis_result"]["breach_count"],
          anomaly_present: checkAnomaly(
            metricsItems[key]["metric_status"],
            metricsItems[key]["analysis_result"]["has_breached"]
          ),
        });
      }
    }
    return filteredArray;
  };

  const checkAnomaly = (metric_status, has_breached): string =>
    metric_status === "success" && has_breached ? "True" : "False";

  return (
    <AppLayout
      content={
        <ContentLayout
          header={<Header variant="h1">{dataSource} Insights </Header>}
        >
          <SpaceBetween size="l">
            {tableItems.length > 0 ? (
              <Table
                id="Metrics_Table_Listview"
                columnDefinitions={[
                  {
                    id: "serviceName",
                    header: "Service Name",
                    cell: (e) => e.service_name,
                  },
                  {
                    id: "igraph",
                    header: "Igraph",
                    cell: (e) => (
                      <a target="_blank" href={e.igraph_link_original}>
                        {" "}
                        <img
                          alt="graph anomalies"
                          height="200px"
                          width="266px"
                          src={e.igraph_link_modified}
                        />{" "}
                      </a>
                    ),
                  },
                  {
                    id: "anomalyPresent",
                    header: "Anomaly Present",
                    cell: (e) => e.anomaly_present,
                  },
                  {
                    id: "abnormalityStartTime",
                    header: "Abnormality Start Time",
                    cell: (e) => e.anomaly_start_time,
                  },
                  {
                    id: "breachCount",
                    header: "Breach Count",
                    cell: (e) => e.breach_count,
                    width: 200,
                  },
                ]}
                items={getMetricsDetails()}
              />
            ) : (
              <div style={{ margin: "auto", width: "50%" }}>
                <Spinner size="large" />
              </div>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
      navigation={<Navigation />}
    />
  );
}
