import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import DataSourcesCards from "./DataSourcesCards";
import { Recommendation } from "./Recommendation";
import AnomalyGraph from "./GraphedAnomalies";
import Header from "@amzn/awsui-components-react/polaris/header";
import Navigation from "../SideNavigation";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import EventsTimeline from "./eventsTimeline/EventsTimeline";
import {Ticket, Workflow} from "../../tickets-api/generated-src";
import WorkflowsExecuted from "./WorkflowsExecuted";

export default function DashboardHome() {
  /*
       Dashboard component containing datasource cards, graphed anomalies, timeline and recommendations
   */

  const { ticketId } = useParams<{ ticketId: string }>();
  const [allWorkflows, setAllWorkflows] = useState<Workflow[]>();
  const [allItems, setAllItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  // const [toolboxContent, setToolboxContent] = useState<React.Component>();
  // const [appLayoutRef, setAppLayoutRef] = useState<any>();

  useEffect(() => {
    void (async () => {
      setAllItems((await TicketsApi.showTicketById(ticketId)).data);
    })();
  }, [ticketId]);

  useEffect(() => {
    void (async () => {
      setAllWorkflows((await TicketsApi.getWorkflowsByTicketId(ticketId)).data);
    })();
  },[ticketId]);

  // function updateToolboxContent(content: React.Component) {
  //   setToolboxContent(content);
  //   if (appLayoutRef) {
  //     appLayoutRef.openTools();
  //   }
  // }

  const allSourceItems: Ticket[] = allItems || [];
  return (
    <AppLayout
      id="Dashboard"
      // tools={toolboxContent}
      // ref={setAppLayoutRef}
      content={
        <ContentLayout
          header={<Header variant="h1">TicketId: {ticketId}</Header>}
        >
          <WorkflowsExecuted data={allWorkflows} />
          <br/>
          <DataSourcesCards data={allSourceItems} />
          <br />
          <AnomalyGraph data={allSourceItems} />
          <br />
          <Recommendation data={allSourceItems} />
          <br />
          <EventsTimeline
              ticketItems={allSourceItems}
              // updateToolboxContent={updateToolboxContent}
          />
        </ContentLayout>
      }
      navigation={<Navigation />}
    />
  );
}
