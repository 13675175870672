import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import { ColumnLayout, Container, Grid } from "@amzn/awsui-components-react";
import { Ticket } from "../../tickets-api/generated-src";
import MitigationLevers from "./mitigationLever/MitigationLevers";

export function Recommendation(props) {
  /**
   * Component to render recommendations
   */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const allSourceItems: Ticket[] = props.data || [];

  const recommendationItems = allSourceItems.filter((item) => {
    if ("recommendedServices" in item) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <>
      {recommendationItems.length > 0 &&
        recommendationItems.map((item) => {
          if (
            item.recommendedServices.length == 0 &&
            item.recommendedRootCauses.length == 0
          ) {
            return <></>;
          } else {
            return (
              <Container
                header={
                  <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <Header
                      variant="h2"
                      description="View updates we recommend to address the anomalies in this insight"
                    >
                      Recommendations
                    </Header>
                    <Header variant="h2">Mitigation Levers</Header>
                  </Grid>
                }
              >
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <span>
                      {item.recommendedServices.map((service) => {
                        return (
                          <div>
                            Based on historical data {service} has very high
                            chances of affecting the service
                          </div>
                        );
                      })}
                      <span>
                        {item.recommendedRootCauses.map((rootcause) => {
                          return (
                            <div>
                              Based on historical data {rootcause} has very high
                              chances of affecting the service
                            </div>
                          );
                        })}
                      </span>
                    </span>
                  </div>
                  <div>
                    <MitigationLevers />
                  </div>
                </ColumnLayout>
              </Container>
            );
          }
        })}
    </>
  );
}
