import { Ticket } from "../../tickets-api/generated-src";

export const formatDate = (epochDate: number) => {
  /*
     Returns date in iso format
    :param epochDate: date in epoch format
    :rtype: string

    */
  const isoDate = new Date(epochDate * 1000).toISOString();
  return isoDate;
};

const ticketCreationTime = (dataSourceList: Array<Ticket>) => {
  /*
     Returns the ticket creationtime
    :param dataSourceList: Array of dataSources details such as MCM, APOLLO, WEBLAB etc
    :rtype: date string

    */
  let ticketCreationDate = "9999-99-99 00:00:00"; //initial random value
  for (const sourceType of dataSourceList) {
    if ("createdDateTime" in sourceType) {
      ticketCreationDate = sourceType["createdDateTime"];
      break;
    }
  }

  return ticketCreationDate;
};

const filterSourceItems = (
  allSourceItems: Array<Ticket>,
  dataSource: string
) => {
  /*
    Common function which returns the array containing specific dataSource details
   :param allSourceItems: array containing details of all dataSources
   :param dataSource: APOLLO/MCM/WEBLAB etc
   :rtype: array

   */
  const sourceTypeItems = allSourceItems.filter((item) => {
    /*
          Filters Array based on the required dataSource
         */
    return item.sourceType === dataSource;
  });
  return sourceTypeItems;
};

export const anomalyMetricsServices = (
  metricType: string,
  dataSourceList: Array<Ticket>
) => {
  /*
    Returns the total number of services which have anomaly for a particular metric (THROTTLING,CPU_UTILISATION,DISK_USAGE,MEM_ACTIVE,TRAFFIC_SURGE,VIP_SPILLOVERS)
   :anomaly condition: if has_breached is true and metric_status is success
   :param metricType: THROTTLING/CPU_UTILISATION/DISK_USAGE/MEM_ACTIVE/TRAFFIC_SURGE/VIP_SPILLOVERS
   :param dataSourceList: Array of dataSources details such as MCM, APOLLO, WEBLAB,THROTTLING etc
   :rtype: integer

   */

  const sourceTypeItems = filterSourceItems(dataSourceList, metricType);

  if (sourceTypeItems.length > 0) {
    const servicesList = sourceTypeItems[0]["compressedData"]; //list of services
    const serviceNames = Object.keys(servicesList);
    let totalAnomalyServices = 0;
    serviceNames.forEach((serviceName, index) => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        servicesList[serviceName]["metric_status"] === "success" &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        servicesList[serviceName]["analysis_result"]["has_breached"]
      ) {
        totalAnomalyServices += 1;
      }
    });
    return totalAnomalyServices;
  } else {
    return 0;
  }
};

export const totalMetricsServices = (
  metricType: string,
  allSourceItems: Array<Ticket>
) => {
  /*
    Returns the total number of services analysed for a particular metric (THROTTLING,CPU_UTILISATION,DISK_USAGE,MEM_ACTIVE,TRAFFIC_SURGE,VIP_SPILLOVERS)
   :param metricType: THROTTLING/CPU_UTILISATION/DISK_USAGE/MEM_ACTIVE/TRAFFIC_SURGE/VIP_SPILLOVERS
   :param dataSourceList: Array of dataSources details such as MCM, APOLLO, WEBLAB,THROTTLING etc
   :rtype: integer

   */

  const sourceTypeItems = filterSourceItems(allSourceItems, metricType);

  if (sourceTypeItems.length > 0) {
    const serviceList = sourceTypeItems[0]["compressedData"];
    const serviceNames = Object.keys(serviceList);
    return serviceNames.length;
  } else {
    return 0;
  }
};

export const ongoingProactiveInsights = (
  dataSourceType: string,
  serviceType: string,
  allSourceItems: Array<Ticket>
) => {
  /*
    Returns the total number of on going proactive insights for a particular dataSource(APOLLO, MCM, WEBLAB, SEV2) type (Primary Service, Dependency Service)
   :criteria to check proactive insights: total number of events for a particular dataSource whose the updation time lies between ticketcreation time and 12 hours before ticketcreation time
   :param dataSourcetype: APOLLO/MCM/WEBLAB/SEV2
   :param serviceType: Primary Service/Dependency Service
   :param allSourceItems: Array of dataSources details such as MCM, APOLLO, WEBLAB,THROTTLING etc
   :rtype: integer

   */
  let countProactiveInsights = 0;
  const ticketCreatedDate = ticketCreationTime(allSourceItems);
  const ticketCreatedObject = new Date(ticketCreatedDate);
  const ticketCreatedDateInt = ticketCreatedObject.valueOf();
  const twelveHoursPrevTime = new Date(
    ticketCreatedDateInt - 12 * 60 * 60 * 1000
  );

  const sourceTypeItems = filterSourceItems(allSourceItems, dataSourceType);

  if (sourceTypeItems.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const serviceTypeList =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      sourceTypeItems[0]["compressedData"][serviceType]["items"];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    serviceTypeList.forEach((item) => {
      if ("updated_at" in item) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const deploymentDate = new Date(item["updated_at"] * 1000);
        if (
          deploymentDate > twelveHoursPrevTime &&
          deploymentDate < ticketCreatedObject
        ) {
          countProactiveInsights += 1;
        }
      }
    });
  }

  return countProactiveInsights;
};

export const ongoingReactiveInsights = (
  dataSourceType: string,
  serviceType: string,
  allSourceItems: Array<Ticket>
) => {
  /*
    Returns the total number of on going reactive insights for a particular dataSource(APOLLO, MCM, WEBLAB, SEV2) type (Primary Service, Dependency Service)
   :criteria to check reactive insights: total number of events for a particular dataSource
   :param dataSourcetype: APOLLO/MCM/WEBLAB/SEV2
   :param serviceType: Primary Service/Dependency Service
   :param allSourceItems: Array of dataSources details such as MCM, APOLLO, WEBLAB,THROTTLING etc
   :rtype: integer

   */

  let reactiveInsights = 0;
  const sourceTypeItems = filterSourceItems(allSourceItems, dataSourceType);

  if (sourceTypeItems.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const serviceTypeList =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      sourceTypeItems[0]["compressedData"][serviceType]["items"];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    reactiveInsights = serviceTypeList.length;
  }

  return reactiveInsights;
};
