import "./EventsTimeline.css";
import {Box, Header, SpaceBetween} from "@amzn/awsui-components-react";
import {DemTeamLink} from "../DemTeamLink";

export function EventsTimelineHelpContent() {
    return (
        <div className={"toolbox-parent-box"}>
            <Header>⏰ Events Timeline</Header>
            <br/>
            <Box variant={"div"}>
                <Box variant={"h5"} fontWeight={"bold"}>🔴 Legends</Box>
                <br/>
                <SpaceBetween size={"s"} direction={"vertical"}>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box lse-tickets-group"}/>
                        <div>Large Scale Events Group</div>
                    </SpaceBetween>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box primary-service"}/>
                        <div>Primary Service</div>
                    </SpaceBetween>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box dependency-service"}/>
                        <div>Dependency Service</div>
                    </SpaceBetween>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box apollo"}/>
                        <div>Apollo Event</div>
                    </SpaceBetween>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box mcm"}/>
                        <div>MCM Event</div>
                    </SpaceBetween>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box weblab"}/>
                        <div>Weblab Event</div>
                    </SpaceBetween>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box sev2"}/>
                        <div>Sev2 Event</div>
                    </SpaceBetween>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <div className={"color-box metric"}/>
                        <div>Metric breach</div>
                    </SpaceBetween>
                </SpaceBetween>
                <br/>
                <Box variant={"h5"} fontWeight={"bold"}>⚙ Functions️</Box>
                <SpaceBetween size={"s"} direction={"vertical"}>
                    <div>
                        🔧 Use <b>Show Large Scale Events</b> toggle
                        to show/hide Large Scale Events in the timeline.
                    </div>
                    <div>
                        🔧 Use <b>Show Dependency Services Events</b> toggle
                        to show/hide Dependency Services Events in the timeline.
                    </div>
                    <div>
                        🔧 Use <b>Expand All/Collapse All</b> toggle
                        to Expand/Collapse All Service Groups.
                    </div>
                    <div>
                        🔧 Use <b>Focus</b> button to Focus the timeline on
                        the currently selected events and place them in the center.
                    </div>
                    <div>
                        <b>🔧 Event Specific Toggles</b> toggle to show/hide
                        Specific Events in the timeline.
                    </div>
                </SpaceBetween>
                <br/>
                <Box variant={"h5"} fontWeight={"bold"}>🕹 Usage</Box>
                <SpaceBetween size={"s"} direction={"vertical"}>
                    <Box variant={"p"}>
                        ↕️ Use <b>Vertical scroll</b> to move up/down in the timeline.
                    </Box>
                    <Box variant={"p"}>
                        ↔️ Use <b>Horizontal scroll</b> to move backward/forward in the timeline.
                    </Box>
                    <Box variant={"p"}>
                        🔍 Use <b>Ctrl + Scroll</b> or <b>Pinch with two fingers</b> 🤏
                        on the trackpad to zoom in/out on the timeline.
                    </Box>
                    <Box variant={"p"}>
                        👆 Click on an event to open it in a new tab.
                    </Box>
                </SpaceBetween>
                <br/>
                <Box variant={"h5"} fontWeight={"bold"}>📒 Guide</Box>
                <SpaceBetween size={"s"} direction={"vertical"}>
                    <Box variant={"p"}>
                        🕘 The timeline is in your browser's local time.
                        You may hover over an event to see GMT time of the event.
                    </Box>
                    <Box variant={"p"}>
                        ➡️ There are two types of events:
                        <b>Range events</b>, which have start and end times, or
                        <b>Point events</b>, which have only start times.
                        Point implies either the event occurs only at a specific moment in time,
                        or it isn't yet complete at the time of capture.
                    </Box>
                    <Box>
                        🏁 The <b>Impact Start</b> flag represents the time of
                        the HSE or Sev2 event that triggered DEM.
                    </Box>
                    <Box variant={"p"}>
                        ⬜️ Service groups shows a light shade in the timeline
                        signifying the range of selected events for this group.
                    </Box>
                    <Box>
                        📢 It is recommended not to use the Expand/Collapse Group toggles
                        from within the timeline. It may show events that are not
                        selected from the Event Specific toggles.
                    </Box>
                </SpaceBetween>
                <br/>
                <Box variant={"h5"} fontWeight={"bold"}>📞 Contact Us</Box>
                <SpaceBetween size={"s"} direction={"vertical"}>
                    <Box variant={"p"}>
                        🤔 Something is off? 💭 Got some suggestions?
                        Please let the <DemTeamLink/> know.
                    </Box>
                </SpaceBetween>
            </Box>
        </div>
    );
}
