import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { Box } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { Weblab } from "../../tickets-api/generated-src";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import { useParams } from "react-router-dom";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Navigation from "../SideNavigation";
import { formatDate } from "../commons/OngoingInsights";

export default function WeblabDetails() {
  /*
       Component to display Weblab details
    */
  const [dataSourceItems, setDataSourceItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { dataSource } = useParams<{ dataSource: string }>();
  const { dataSourceType } = useParams<{ dataSourceType: string }>();

  useEffect(() => {
    (async () => {
      setDataSourceItems(
        (
          await TicketsApi.showDataSourceDetails(
            ticketId,
            dataSource,
            dataSourceType
          )
        ).data
      );
    })();
  }, [ticketId]);

  const setItems: Weblab[] = dataSourceItems || [];

  const getWeblabItems = () => {
    const weblabItems: Weblab[] =
      setItems[0]["compressedData"][dataSourceType]["items"];
    return weblabItems;
  };

  return (
    <>
      <AppLayout
        content={
          <ContentLayout
            header={
              <Header variant="h1">
                {dataSource} {dataSourceType} Insights
              </Header>
            }
          >
            <SpaceBetween size="l">
              {setItems.length > 0 ? (
                <Table
                  id="Weblabs_Table_Listview"
                  columnDefinitions={[
                    {
                      id: "weblabName",
                      header: "Weblab Name",
                      cell: (e) => e["properties"]["Weblab Name"],
                      width: 200,
                    },
                    {
                      id: "description",
                      header: "Description",
                      cell: (e) => (
                        <div style={{ whiteSpace: "normal" }}>
                          {e["properties"]["Description"]}
                        </div>
                      ),
                    },
                    {
                      id: "marketplaceId",
                      header: "Obfuscated Marketplace ID",
                      cell: (e) => e["properties"]["Obfuscated Marketplace ID"],
                      width: 200,
                    },
                    {
                      id: "location",
                      header: "Location",
                      cell: (e) => e["properties"]["Location"],
                      width: 200,
                    },

                    {
                      id: "cti",
                      header: "CTI",
                      cell: (e) => (
                        <a
                          target="_blank"
                          href={
                            "https://cti.amazon.com/search?searchtype=0&request=" +
                            e["properties"]["CTI"][0]
                              .replace(/\s+/g, "")
                              .split("/")[2]
                          }
                        >
                          {e["properties"]["CTI"]}
                        </a>
                      ),
                    },
                    {
                      id: "createdAt",
                      header: "Created at",
                      cell: (e) => formatDate(Number(e.created_at)),
                    },
                    {
                      id: "start",
                      header: "Start",
                      cell: (e) => formatDate(Number(e.start)),
                    },
                  ]}
                  items={getWeblabItems()}
                  empty={
                    <Box textAlign="center" color="inherit">
                      <b>No resources</b>
                      <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                      >
                        No Weblab to display.
                      </Box>
                    </Box>
                  }
                />
              ) : (
                <div style={{ margin: "auto", width: "50%" }}>
                  <Spinner size="large" />
                </div>
              )}
            </SpaceBetween>
          </ContentLayout>
        }
        navigation={<Navigation />}
      />
    </>
  );
}
