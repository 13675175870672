import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { Box } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { Ticket } from "../../tickets-api/generated-src";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import { Link } from "react-router-dom";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

export default function TicketsTable(props) {
  /*
      Component to tickets list view table
   */
  const [allTicketsItems, setAllTicketsItems] = useState<Ticket[]>();
  const TicketsApi = TicketsApiFactory();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const startDate = props.StartDate;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const endDate = props.EndDate;

  useEffect(() => {
    void (async () => {
      setAllTicketsItems(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        (await TicketsApi.listTickets(startDate, endDate)).data
      );
    })();
  }, [startDate, endDate]);

  const tableItems = allTicketsItems || [];

  return (
    <>
      {allTicketsItems ? (
        <Table
          id="Hse_Table_Listview"
          columnDefinitions={[
            {
              id: "insightsId",
              header: "Insight Details",
              cell: (e) => (
                <span id={`${e.recordId}`}>
                  <Link to={`/${e.recordId}`}>{e.recordId}</Link>
                </span>
              ),
            },
            {
              id: "ticketLink",
              header: "Ticket Link",
              cell: (e) => (
                <a
                  target="_blank"
                  href={"https://t.corp.amazon.com/" + e["recordId"]}
                >
                  Ticket Link
                </a>
              ),
            },
            {
              id: "ticketCreationTime",
              header: "Ticket creation time",
              cell: (e) => e.createdDateTime,
              sortingField: "name",
            },
          ]}
          items={tableItems}
          loadingText="Loading resources"
          empty={
            <Box textAlign="center" color="inherit">
              <b>No insights</b>
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No insights to display.
              </Box>
            </Box>
          }
        />
      ) : (
        <div style={{ margin: "auto", width: "50%" }}>
          <Spinner size="large" />
        </div>
      )}
    </>
  );
}
