import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { Box } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { MCM } from "../../tickets-api/generated-src";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import { useParams } from "react-router-dom";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Navigation from "../SideNavigation";
import { formatDate } from "../commons/OngoingInsights";

export default function McmDetails() {
  /*
       Component to display MCM's
    */
  const [dataSourceItems, setDataSourceItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { dataSource } = useParams<{ dataSource: string }>();
  const { dataSourceType } = useParams<{ dataSourceType: string }>();

  useEffect(() => {
    (async () => {
      setDataSourceItems(
        (
          await TicketsApi.showDataSourceDetails(
            ticketId,
            dataSource,
            dataSourceType
          )
        ).data
      );
    })();
  }, []);

  const setItems: MCM[] = dataSourceItems || [];

  const getMcmItems = () => {
    const mcmItems: MCM[] =
      setItems[0]["compressedData"][dataSourceType]["items"];
    return mcmItems;
  };

  return (
    <AppLayout
      content={
        <ContentLayout
          header={
            <Header variant="h1">
              {dataSource} {dataSourceType} Insights{" "}
            </Header>
          }
        >
          <SpaceBetween size="l">
            {setItems.length > 0 ? (
              <Table
                id="Mcm_Table_Listview"
                columnDefinitions={[
                  {
                    id: "description",
                    header: "Description",
                    cell: (e) => (
                      <div style={{ whiteSpace: "normal" }}>
                        {e["properties"]["Description"]}
                      </div>
                    ),
                  },
                  {
                    id: "url",
                    header: "URL",
                    cell: (e) => e["properties"]["URL"],
                    width: 200,
                  },
                  {
                    id: "region",
                    header: "Region",
                    cell: (e) =>
                      e["properties"]["Region"].map((region) => (
                        <ul>
                          <a>
                            <li>{region}</li>
                          </a>
                        </ul>
                      )),
                  },
                  {
                    id: "cti",
                    header: "CTI",
                    cell: (e) => (
                      <a
                        target="_blank"
                        href={
                          "https://cti.amazon.com/search?searchtype=0&request=" +
                          e["properties"]["CTI"][0]
                            .replace(/\s+/g, "")
                            .split("/")[2]
                        }
                      >
                        {e["properties"]["CTI"]}
                      </a>
                    ),
                  },
                  {
                    id: "createdAt",
                    header: "Created at",
                    cell: (e) => formatDate(Number(e.created_at)),
                  },
                  {
                    id: "start",
                    header: "Start",
                    cell: (e) => formatDate(Number(e.start)),
                  },
                ]}
                items={getMcmItems()}
                empty={
                  <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                      No MCM to display.
                    </Box>
                  </Box>
                }
              />
            ) : (
              <div style={{ margin: "auto", width: "50%" }}>
                <Spinner size="large" />
              </div>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
      navigation={<Navigation />}
    />
  );
}
