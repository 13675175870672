import { TicketsApi } from "./generated-src";
import { getAppSetting } from "../config/AppSettings";
import { Configuration } from "./generated-src/configuration";

export default function () {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const apiBasePath = `${getAppSetting("apiUrl")}/jwt`;
  console.log("apibasepath:", apiBasePath);
  return new TicketsApi(new Configuration({ basePath: apiBasePath }));
}
