import {
    DateType,
    IdType,
    SubGroupVisibilityOptions,
    TimelineAlignType,
    TimelineItem,
    TimelineItemEditableType,
    TimelineItemType,
    TimelineOptionsGroupOrderType,
    TimelineGroup
} from "vis-timeline/types";
import {
    createTooltip,
    getMillisFromSeconds,
    getSecondsFromMillis,
    getStrFromEventsProperties,
    getWeblabTreatmentsFromProperties
} from "./EventTimelineUtils";


/**
 * Constants for event types
 */
export const APOLLO = "APOLLO";
export const MCM = "MCM";
export const WEBLABS = "WEBLABS";
export const SEV2 = "SEV2";
export const LSE = "LSE";
export const TICKET_DETAILS = "TICKET_DETAILS";
export const SERVICES_DATA = "SERVICES_DATA";
export const THROTTLING = "THROTTLING";
export const TRAFFIC_SURGE = "TRAFFIC_SURGE";
export const VIP_SPILLOVERS = "VIP_SPILLOVERS";
export const CPU_UTILISATION = "CPU_UTILISATION";
export const MEM_ACTIVE = "MEM_ACTIVE";
export const DISK_USAGE = "DISK_USAGE";
export const SERVICE_UNAVAILABILITY = "SERVICE_UNAVAILABILITY";
export const METRIC = "METRIC";

const PRIMARY_SERVICES = "Primary Services";
const DEPENDENCY_SERVICES = "Dependency Services";

const CUR_MILLI_SEC_LENGTH = 13;
const CUR_SEC_LENGTH = 10;

/**
 * Defining offsets in ms before the first event and after the last event in the timeline.
 * This will prevent events at extreme points being cornered in the timeline.
 */
const TIMELINE_RANGE_OFFSET_MILLIS = 3 * 60 * 60 * 1000;

/**
 * Enum defining the Type of Event Groups.
 * PRIMARY for primary services groups, DEPENDENCY for dependency services groups.
 * LSE for Large scale events group, and UNKNOWN for default.
 */
export enum EventGroupType {
    PRIMARY = "PRIMARY_GROUP",
    DEPENDENCY = "DEPENDENCY_GROUP",
    LSE = "LSE_GROUP",
    UNKNOWN = "UNKNOWN"
}

/**
 * Base abstract class for Event Timeline Item implementing interface TimelineItem of vis-timeline.
 */
export abstract class TimelineEvent implements TimelineItem {
    className?: string;
    align?: TimelineAlignType;
    content: string;
    end?: DateType;
    group?: IdType;
    id: IdType;
    start: DateType;
    style?: string;
    subgroup?: IdType;
    title: string;
    type?: TimelineItemType;
    editable?: TimelineItemEditableType;
    selectable?: boolean;
    eventType: string;
    url?: string;

    /**
     * Constructor to be called from extending classes for initializing a TimelineItem instance.
     * @param rawEventObject The object containing `event_id`, `start`, `end` props to the least.
     * @param eventType The type of this event to distinguish between different events.
     * @protected Protected as it can't be called directly, only from extending classes.
     * @throws Error if above three props are not present or invalid.
     */
    protected constructor(rawEventObject: any, eventType: string) {
        this.id = rawEventObject["event_id"];
        this.start = getMillisFromSeconds(rawEventObject["start"]);
        const end: number = getMillisFromSeconds(rawEventObject["end"]);
        if (end > this.start) {
            this.end = end;
            this.type = "range";
            this.title = `<p>From ${new Date(this.start).toUTCString()} ` +
                `to ${new Date(this.end).toUTCString()}</p>`;
        } else {
            this.type = "point";
            this.title = `<p>At ${new Date(this.start).toUTCString()}</p>`;
        }
        this.content = this.id.toString();
        this.eventType = eventType;
    }
}


/**
 * Base abstract class for Event Timeline Groups
 * implementing interface TimelineGroup of vis-timeline.
 */
export class EventGroup implements TimelineGroup {
    className?: string;
    content: string | HTMLElement;
    id: IdType;
    style?: string;
    order?: number;
    subgroupOrder?: TimelineOptionsGroupOrderType;
    title?: string;
    visible?: boolean;
    nestedGroups?: IdType[];
    showNested?: boolean;
    subgroupVisibility?: SubGroupVisibilityOptions;
    groupType: EventGroupType;
    eventType?: string
    eventIds: string[];
    isCritical: boolean;

    /**
     * Constructor to initialize an instance of EventGroup.
     * @param id The unique identifier for the group.
     */
    constructor(id: string) {
        this.id = id;
        this.content = id;
        this.groupType = EventGroupType.UNKNOWN;
        this.eventIds = [];
        this.isCritical = false;
    }
}


/**
 * Abstract class representing events that are related to a service.
 * It extends to TimelineEvent abstract class.
 */
abstract class ServiceSpecificEvent extends TimelineEvent {
    serviceName: string;

    /**
     * Constructor to initialize an instance of ServiceSpecificEvent.
     * @param rawEventObject The object containing `event_id`, `start`, `end` props to the least.
     * @param eventType The type of this event to distinguish between different events.
     * @protected Protected as it can't be called directly, only from extending classes.
     * @throws Error if above three props and `service_name` are not present or invalid.
     */
    protected constructor(rawEventObject: any, eventType: string) {
        super(rawEventObject, eventType);
        if (!rawEventObject["service_name"]) {
            throw new Error("Service name expected for a service specific event.");
        }
        this.serviceName = rawEventObject["service_name"];
    }
}


/**
 * Concrete implementation of ServiceSpecificEvent representing Mcm Events.
 */
class McmEvent extends ServiceSpecificEvent {
    /**
     * Constructor to initialize an instance of McmEvent.
     * @param rawEventObject The object containing `event_id`, `start`,
     * `end`, `service_name` props to the least.
     * @throws Error if above three props are not present or invalid.
     */
    constructor(rawEventObject: Object) {
        super(rawEventObject, MCM);
        const properties = rawEventObject["properties"];
        this.content = getStrFromEventsProperties(properties, "CM ID", true);
        this.title += createTooltip({
            "Status": getStrFromEventsProperties(properties, "Status"),
            "Onwership": getStrFromEventsProperties(properties, "Ownership Hierarchy"),
            "Resolver Group": getStrFromEventsProperties(properties, "Resolver Group")
        });
        this.className = "mcm";
        this.url = getStrFromEventsProperties(properties, "URL");
    }
}


/**
 * Concrete implementation of ServiceSpecificEvent representing Apollo Events.
 */
class ApolloEvent extends ServiceSpecificEvent {
    /**
     * Constructor to initialize an instance of ApolloEvent.
     * @param rawEventObject The object containing `event_id`, `start`,
     * `end`, `service_name` props to the least.
     * @throws Error if above props are not present or invalid.
     */
    constructor(rawEventObject: Object) {
        super(rawEventObject, APOLLO);
        const properties = rawEventObject["properties"];
        const apolloId: string = getStrFromEventsProperties(properties, "Deployment ID", true);
        this.content = apolloId;
        this.title += createTooltip({
            "Status": getStrFromEventsProperties(properties, "Deployment Status"),
            "Ownership": getStrFromEventsProperties(properties, "Ownership Hierarchy"),
            "Environment Stage Name":
                getStrFromEventsProperties(properties, "Environment Stage Name")
        });
        this.className = "apollo";
        this.url = `https://apollo.amazon.com/deployments/${apolloId}`;
    }
}


/**
 * Concrete implementation of ServiceSpecificEvent representing Weblab Events.
 */
class WeblabEvent extends ServiceSpecificEvent {
    /**
     * Constructor to initialize an instance of WeblabEvent.
     * @param rawEventObject The object containing `event_id`, `start`,
     * `end`, `service_name` props to the least.
     * @throws Error if above props are not present or invalid.
     */
    constructor(rawEventObject: Object) {
        const properties = rawEventObject["properties"];
        const weblabName: string = getStrFromEventsProperties(properties, "Weblab Name", true);
        super({
            ...rawEventObject,
            "event_id": weblabName + rawEventObject["start"].toString()
        }, WEBLABS);
        this.content = weblabName;
        this.title += createTooltip({
            "Activation Type": getStrFromEventsProperties(properties, "Activation Type"),
            "Exposure Percentage": getStrFromEventsProperties(properties, "Exposure Percentage"),
            "Creator Login": getStrFromEventsProperties(properties, "Creator Login"),
            "Description": getStrFromEventsProperties(properties, "Description"),
            "Treatments": getWeblabTreatmentsFromProperties(properties)
        });
        this.className = "weblab";
        this.url = `https://weblab.amazon.com/wl/${weblabName}`;
    }
}

/**
 * Concrete implementation of ServiceSpecificEvent representing Sev2 Events.
 */
class Sev2Event extends ServiceSpecificEvent {
    /**
     * Constructor to initialize an instance of Sev2Event.
     * @param rawEventObject The object containing `event_id`, `start`,
     * `end`, `service_name` props to the least.
     * @throws Error if above props are not present or invalid.
     */
    constructor(rawEventObject: Object) {
        super({
            "event_id": rawEventObject["id"],
            "start": new Date(rawEventObject["createDate"]).getTime()/1000,
            "end": 0,
            "service_name": rawEventObject["serviceName"]
        }, SEV2);

        this.content =  "🚩" + rawEventObject["id"];
        this.title += createTooltip({
            "Assigned Group": rawEventObject["assignedGroup"],
            "Title": rawEventObject["title"],
            "CTI": `${rawEventObject["category"]}/` +
                `${rawEventObject["type"]}/${rawEventObject["item"]}`,
            "Status": rawEventObject["status"],
            "Alias": rawEventObject["aliases"]
        });
        this.className = "sev2";
        this.url = `https://t.corp.amazon.com/${rawEventObject["id"]}`;
    }
}


/**
 * Interface to define object structure to store required MetricDetails
 * while initializing Metric Events.
 */
interface MetricDetails {
    eventId: string,
    serviceName: string,
    metricValue: string,
    time: number,
    metricType: string,
    breachCount: number,
    analysisType: string,
    iGraph: string,
    deviationRate?: number
}


/**
 * Concrete implementation of ServiceSpecificEvent representing Metric breach Events.
 */
class MetricEvent extends ServiceSpecificEvent {
    /**
     * Constructor to initialize an instance of MetricEvent.
     * @param metricDetails An object of MetricDetails type representing
     * metric details for initializing MetricEvent instance.
     * @throws Error if metricDetails has invalid values.
     */
    constructor(metricDetails: MetricDetails) {
        super({
            "event_id": metricDetails.eventId,
            "start": getSecondsFromMillis(metricDetails.time),
            "end": 0,
            "service_name": metricDetails.serviceName
        }, METRIC);
        this.content = "🚩" + metricDetails.metricType + " - " + metricDetails.metricValue;
        this.title += createTooltip({
            "Breach Count": metricDetails.breachCount,
            "Deviation Rate": metricDetails.deviationRate || "NA",
            "Analysis Type": metricDetails.analysisType
        });
        this.className = "metric";
        this.url = metricDetails.iGraph;
    }
}


/**
 * Concrete implementation of TimelineEvent representing LSE ticket events.
 */
class LseEvent extends TimelineEvent {
    /**
     * Constructor to initialize instance of LseEvent.
     * @param rawEventObject The object containing valid `event_id`, `properties`.
     * @throws Error if above props are not present or invalid.
     */
    constructor(rawEventObject: Object) {
        super(rawEventObject, LSE);
        const properties = rawEventObject["properties"];
        this.content = rawEventObject["event_id"];
        this.title += createTooltip({
            "Impact": getStrFromEventsProperties(properties, "Impact"),
            "Symptoms": getStrFromEventsProperties(properties, "Symptoms"),
            "Status": getStrFromEventsProperties(properties, "Status")
        });
        this.className = "lse";
        this.url = getStrFromEventsProperties(properties, "Link");
    }
}


/**
 * Helper class to handle ticket data and parse Events, Groups and timeline options from it.
 */
export class TicketDataHelper {

    // Set of event types that are specific to a service.
    static SERVICE_SOURCES: Set<string> = new Set<string>([
        APOLLO,
        MCM,
        WEBLABS,
        SEV2
    ]);

    // Set of event types that are specific metric breaches.
    static METRIC_SOURCES: Set<string> = new Set<string>([
        THROTTLING,
        TRAFFIC_SURGE,
        VIP_SPILLOVERS,
        CPU_UTILISATION,
        MEM_ACTIVE,
        DISK_USAGE,
        SERVICE_UNAVAILABILITY
    ]);

    // Storing information for events could not be
    // transformed because of some missing required information.
    // This can be used for debugging.
    failedToTransformEvents: any[] = [];
    private items: TimelineItem[] = [];
    private groups: EventGroup[] = [];

    // minTime and maxTime for setting start and end range for timeline.
    private minTime: number = new Date().getTime();
    private maxTime: number = 0;

    private eventsMap: { [key: string]: TimelineEvent } = {};
    private groupsMap: { [key: string]: EventGroup } = {};
    private ticketCreatedTime: DateType = 0;
    private primaryServicesSet: Set<string> = new Set<string>();

    /**
     * Constructor to initialize an instance of TicketDataHelper
     * and process the provided ticket data objects.
     * @param ticketSourcesInfo An array of objects representing
     * various ticket events and related information.
     */
    constructor(ticketSourcesInfo: Object[]) {
        this.transformTicketData(ticketSourcesInfo);
    }

    /**
     * Method to transform provided ticket data and initialize class members with respective data.
     * @param ticketSourcesInfo An array of objects representing
     * various ticket events and related information.
     */
    private transformTicketData(ticketSourcesInfo: Object[]): void {

        if (!ticketSourcesInfo) {
            return;
        }

        const primaryServiceSpecificEvents: ServiceSpecificEvent[] = [];
        const dependencyServiceSpecificEvents: ServiceSpecificEvent[] = [];
        const lseEvents: TimelineEvent[] = [];

        this.processServicesData(ticketSourcesInfo);
        ticketSourcesInfo.forEach(sourceInfo => {
            const eventsData = sourceInfo["compressedData"];
            if (!eventsData) {
                return;
            }
            const sourceType = sourceInfo["sourceType"];
            if (TicketDataHelper.SERVICE_SOURCES.has(sourceType)) {
                const servicesEventsMap = this.processServiceEvents(eventsData, sourceType);
                primaryServiceSpecificEvents.push(...servicesEventsMap[PRIMARY_SERVICES]);
                dependencyServiceSpecificEvents.push(...servicesEventsMap[DEPENDENCY_SERVICES]);
            } else if (TicketDataHelper.METRIC_SOURCES.has(sourceType)) {
                const metricEventsMap = this.processMetricEvents(eventsData, sourceType);
                primaryServiceSpecificEvents.push(...metricEventsMap[PRIMARY_SERVICES]);
                dependencyServiceSpecificEvents.push(...metricEventsMap[DEPENDENCY_SERVICES]);
            } else if (sourceType === LSE) {
                lseEvents.push(...this.processLseEvents(eventsData));
            } else if (sourceType === TICKET_DETAILS) {
                this.ticketCreatedTime = TicketDataHelper.getTicketCreationTime(eventsData);
                if (this.ticketCreatedTime) {
                    this.adjustMinMaxTimeForEvent(this.ticketCreatedTime, this.ticketCreatedTime);
                }
            }
        });

        const primaryServiceGroups: EventGroup[] =
            this.getServiceEventsGroups(primaryServiceSpecificEvents);
        primaryServiceGroups.forEach(group => {
            group.className = "primary-service-group";
            group.groupType = EventGroupType.PRIMARY;
            group.content = group.isCritical ? `❗${group.content}` : group.content;
        })
        const dependencyServiceGroups: EventGroup[] =
            this.getServiceEventsGroups(dependencyServiceSpecificEvents);
        dependencyServiceGroups.forEach(group => {
            group.showNested = false;
            group.className = "secondary-service-group";
            group.groupType = EventGroupType.DEPENDENCY;
            group.content = group.isCritical ? `❗${group.content}` : group.content;
        });
        const lseGroup: EventGroup = this.getLseGroup(lseEvents);

        this.groups.push(
            lseGroup,
            ...primaryServiceGroups,
            ...dependencyServiceGroups
        );
        this.items.push(
            ...lseEvents,
            ...primaryServiceSpecificEvents,
            ...dependencyServiceSpecificEvents
        );
    }

    /**
     * Method to process Service specific events.
     * @param eventsData The eventObject for service specific sourceType.
     * @param eventSource The type of the source for eventsData.
     * @return Map of primary or dependency service to list of corresponding service events.
     */
    private processServiceEvents(eventsData: Object, eventSource: string): Object {
        const primaryServicesData = eventsData[PRIMARY_SERVICES];
        const dependencyServicesData = eventsData[DEPENDENCY_SERVICES];
        let events = {};
        if (primaryServicesData && primaryServicesData["items"] instanceof Array) {
            events[PRIMARY_SERVICES] =
                this.processEventItems(primaryServicesData["items"], eventSource);
        }
        if (dependencyServicesData && dependencyServicesData["items"] instanceof Array) {
            events[DEPENDENCY_SERVICES] =
                this.processEventItems(dependencyServicesData["items"], eventSource);
        }
        return events;
    }

    /**
     * Method to process Metric breach events.
     * @param eventsData The eventObject for metric specific sourceType.
     * @param eventSource The type of the source for metric eventsData object.
     * @return Map of primary or dependency service to list of corresponding metric events.
     */
    private processMetricEvents(
        eventsData: Object, eventSource: string): { [key: string]: MetricEvent[] } {

        let events = {};
        events[PRIMARY_SERVICES] = [];
        events[DEPENDENCY_SERVICES] = [];

        Object.keys(eventsData).forEach(serviceName => {
            if (!(eventsData[serviceName]
                && eventsData[serviceName]["analysis_result"]
                && eventsData[serviceName]["analysis_result"]["has_breached"])) {
                return;
            }
            const analysisResult = eventsData[serviceName]["analysis_result"];
            if (analysisResult["abnormality_start_time"]) {
                try {
                    let date: Date;

                    // Handling difference scenarios of receiving date in metric events.
                    if (isNaN(analysisResult["abnormality_start_time"])) {
                        date = new Date(analysisResult["abnormality_start_time"]);
                    } else if (analysisResult["abnormality_start_time"].length === CUR_SEC_LENGTH) {
                        date = new Date(getMillisFromSeconds(
                            parseInt(analysisResult["abnormality_start_time"])));
                    } else if (
                        analysisResult["abnormality_start_time"].length == CUR_MILLI_SEC_LENGTH) {
                        date = new Date(parseInt(analysisResult["abnormality_start_time"]));
                    } else {
                        throw new TypeError(`Expected a valid date type for metric event.` +
                            `Got: ${analysisResult["abnormality_start_time"]}`);
                    }

                    const metricDetails: MetricDetails = {
                        analysisType: eventsData[serviceName]["query"] ?
                            eventsData[serviceName]["query"]["analysis_type"]: "NA",
                        breachCount: analysisResult["breach_count"],
                        eventId: serviceName + eventSource + date.getTime().toString(),
                        metricType: eventSource,
                        metricValue: analysisResult["breach_count"],
                        serviceName: serviceName,
                        time: date.getTime(),
                        iGraph: eventsData[serviceName]["igraph_link"],
                        deviationRate: analysisResult["metric_deviation_rate"] ||
                            analysisResult["previous_week_deviation_rate"]
                    };
                    const event = new MetricEvent(metricDetails);
                    if (this.primaryServicesSet.has(event.serviceName)) {
                        events[PRIMARY_SERVICES].push(event);
                    } else {
                        events[DEPENDENCY_SERVICES].push(event);
                    }
                    this.eventsMap[event.id] = event;
                } catch (e) {
                    this.failedToTransformEvents.push([e, JSON.stringify(eventsData)]);
                }
            }
        });

        return events;
    }

    /**
     * Method to process LSE events.
     * @param eventsData The eventObject for LSE sourceType.
     * @return An array of LSE events.
     */
    private processLseEvents(eventsData: Object): TimelineEvent[] {
        if (eventsData["LSE"] && eventsData["LSE"]["items"] instanceof Array) {
            const rawItems: Object[] = eventsData["LSE"]["items"];
            return this.processEventItems(rawItems, LSE);
        }
        return [];
    }

    /**
     * Method to get TicketCreation time from Ticket details object.
     * @param ticketDetails Object representing ticket specific information.
     * @return The DateType object of ticket creation time. 0, if date is not available.
     */
    private static getTicketCreationTime(ticketDetails: Object): DateType {
        if (ticketDetails["createDate"]) {
            return new Date(ticketDetails["createDate"]);
        }
        return 0;
    }

    /**
     * Method to process services data and populate primaryServicesSet with primary services.
     * @param ticketSourcesInfo An array of objects representing
     * various ticket events and related information.
     */
    private processServicesData(ticketSourcesInfo: Object[]) {
        const servicesInfo = ticketSourcesInfo
            .find(sourceInfo => sourceInfo["sourceType"] === SERVICES_DATA);
        if (servicesInfo && servicesInfo["compressedData"]
            && servicesInfo["compressedData"][PRIMARY_SERVICES]) {
            Object.keys(servicesInfo["compressedData"][PRIMARY_SERVICES])
                .forEach(primaryService => this.primaryServicesSet.add(primaryService));
        }
    }


    /**
     * Method to add events for background tint to service groups in the timeline
     * representing the range of selected events for the service group.
     */
    public addBackgroundForServiceGroups() {
        interface StartEndTimes {
            start: number,
            end: number
        }
        const groupsStartEndTimes: { [key: string]: StartEndTimes } = {};

        this.groups.forEach(group => {
            if (group.eventType) {
                return;
            }
            group.nestedGroups?.forEach(nestedGroupId => {
                if (!this.groupsMap[nestedGroupId].visible) {
                    return;
                }
                this.groupsMap[nestedGroupId].eventIds.forEach(id => {
                    const event = this.getEventById(id);
                    const start = event.start;
                    const end = event.end ? event.end : event.start;
                    if (!groupsStartEndTimes[group.id]) {
                        groupsStartEndTimes[group.id] = {
                            start: new Date(start).getTime(),
                            end: new Date(end).getTime()
                        }
                    } else {
                        groupsStartEndTimes[group.id] = {
                            start:
                                groupsStartEndTimes[group.id].start > new Date(start).getTime() ?
                                new Date(start).getTime() : groupsStartEndTimes[group.id].start,
                            end:
                                groupsStartEndTimes[group.id].end < new Date(end).getTime() ?
                                new Date(end).getTime() : groupsStartEndTimes[group.id].end
                        }
                    }
                });
            });
        });

        Object.keys(groupsStartEndTimes).forEach(groupId => {
            const event: TimelineEvent = {
                id: groupId + "-background",
                start: groupsStartEndTimes[groupId].start,
                end: groupsStartEndTimes[groupId].end,
                type: "background",
                content: "",
                eventType: "",
                group: groupId,
                title: ""
            };
            this.eventsMap[event.id] = event;
            this.items.push(event);
        });
    }


    /**
     * Method to process event items and create event instance
     * based on sourceType and add it the eventsMap.
     * @param eventItems The list of event objects.
     * @param eventSource The type of the event.
     * @return The list of processed event instances.
     */
    private processEventItems(eventItems: Object[], eventSource: string): TimelineEvent[] {
        if (!eventItems.length) {
            return [];
        }
        const events: TimelineEvent[] = []
        eventItems.forEach(item => {
            try {
                const event = TicketDataHelper.createEvent(item, eventSource);
                if (event.id in this.eventsMap) {
                    return;
                }
                this.adjustMinMaxTimeForEvent(event.start, event.end);
                events.push(event);
                this.eventsMap[event.id] = event;
            } catch (e) {
                this.failedToTransformEvents.push([e, JSON.stringify(item)]);
            }
        })
        return events;
    }


    /**
     * The method to adjust range of timeline based on events being created for the timeline.
     * @param startTime The start time of an event.
     * @param endTime The end time of an event. Can be undefined for point events.
     */
    private adjustMinMaxTimeForEvent(startTime: DateType, endTime: DateType | undefined) {
        if (this.maxTime === 0) {
            this.maxTime = new Date(startTime).getTime();
        }
        if (this.minTime > startTime) {
            this.minTime = new Date(startTime).getTime();
        }
        if (endTime && this.maxTime < endTime) {
            this.maxTime = new Date(endTime).getTime();
        }
    }


    /**
     * Method to create an event based on eventObject and eventSource type.
     * @param eventObject The object for event data.
     * @param eventSource The source type of the event.
     * @return An instance of Event specific class based on eventSource type.
     * @throws Error if eventObject or eventSource is invalid or unexpected.
     */
    private static createEvent(
        eventObject: Object, eventSource: string): TimelineEvent {
        switch (eventSource) {
            case APOLLO:
                return new ApolloEvent(eventObject);
            case MCM:
                return new McmEvent(eventObject);
            case WEBLABS:
                return new WeblabEvent(eventObject);
            case SEV2:
                return new Sev2Event(eventObject);
            case LSE:
                return new LseEvent(eventObject);
            default:
                throw new Error(`Invalid type: ${eventSource} for TimelineEvent.`)
        }
    }


    /**
     * Method to process service specific events and form groups and add to groupsMap.
     * @param events The list of service specific events.
     * @return The list of groups formed from given events.
     */
    private getServiceEventsGroups(events: ServiceSpecificEvent[]): EventGroup[] {
        const groups: EventGroup[] = [];
        events.forEach(event => {
            if (!(event.serviceName in this.groupsMap)) {
                this.groupsMap[event.serviceName] = TicketDataHelper.createServiceGroup(event);
                groups.push(this.groupsMap[event.serviceName]);
            }
            let eventGroupId: string = TicketDataHelper.getServiceEventGroupId(event);
            if (!(eventGroupId in this.groupsMap)) {
                this.groupsMap[eventGroupId] = TicketDataHelper.createEventTypeGroup(event);
                groups.push(this.groupsMap[eventGroupId]);
                this.groupsMap[event.serviceName].nestedGroups?.push(eventGroupId);
            }
            this.groupsMap[event.serviceName].eventIds.push(<string>event.id);
            this.groupsMap[eventGroupId].eventIds.push(<string>event.id);
            if (event instanceof Sev2Event || event instanceof MetricEvent) {
                this.groupsMap[event.serviceName].isCritical = true;
            }
            event.group = eventGroupId;
        })
        return groups;
    }


    /**
     * Method to form id for service nested groups.
     * @param event The service specific event to get group id for.
     * @return The id of the nested group.
     */
    private static getServiceEventGroupId(event: ServiceSpecificEvent): string {
        return event.serviceName + event.eventType;
    }


    /**
     * Method to create service group for given service specific event.
     * @param event The instance of the event.
     * @private The EventGroup instance representing service group.
     */
    private static createServiceGroup(event: ServiceSpecificEvent): EventGroup {
        const group = new EventGroup(event.serviceName);
        group.content = event.serviceName;
        group.nestedGroups = [];
        return group;
    }


    /**
     * Method to create nested event type group for a given service specific event.
     * @param event The instance of the event.
     * @return The EventGroup instance representing nested group for given event.
     */
    private static createEventTypeGroup(event: ServiceSpecificEvent): EventGroup {
        const group = new EventGroup(TicketDataHelper.getServiceEventGroupId(event));
        group.content = event.eventType;
        group.eventType = event.eventType;
        return group;
    }


    /**
     * Method to form LSE group.
     * @param events The list of lse events.
     * @return The LSE event group.
     */
    private getLseGroup(events: LseEvent[]): EventGroup {
        const lseGroup = new EventGroup("lse-group");
        lseGroup.content = "LSE";
        lseGroup.className = "lse-group";
        lseGroup.groupType = EventGroupType.LSE;
        this.groupsMap[lseGroup.id] = lseGroup;
        events.forEach(event => event.group = lseGroup.id);
        return lseGroup;
    }

    /**
     * Getter for timeline groups.
     */
    get getInitialGroups(): EventGroup[] {
        return this.groups;
    }

    /**
     * Getter for timeline events or items.
     */
    get getInitialItems(): TimelineItem[] {
        return this.items;
    }

    /**
     * Getter for start range of the timeline with offset.
     */
    get getStartTime(): number {
        return this.minTime - TIMELINE_RANGE_OFFSET_MILLIS;
    }

    /**
     * Getter for end range of the timeline with offset.
     */
    get getEndTime(): number {
        return this.maxTime + TIMELINE_RANGE_OFFSET_MILLIS;
    }

    /**
     * Getter for Impact start time during HSE or Sev2.
     */
    get impactStartTime(): DateType {
        return this.ticketCreatedTime;
    }

    /**
     * Method to get corresponding event for an eventId.
     * @param eventId The id of the event.
     */
    public getEventById(eventId: string): TimelineEvent {
        return this.eventsMap[eventId];
    }

    /**
     * Method to get corresponding event of an event group.
     * @param groupId The id of the group.
     */
    public getGroupById(groupId: string): EventGroup {
        return this.groupsMap[groupId];
    }

}
