import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { Box } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { SevTwo } from "../../tickets-api/generated-src";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import { useParams } from "react-router-dom";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Navigation from "../SideNavigation";
import { formatDate } from "../commons/OngoingInsights";

export default function SevTwoDetails() {
  /*
       Component to display listview of Sev2's
    */
  const [dataSourceItems, setDataSourceItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { dataSource } = useParams<{ dataSource: string }>();
  const { dataSourceType } = useParams<{ dataSourceType: string }>();

  useEffect(() => {
    (async () => {
      setDataSourceItems(
        (
          await TicketsApi.showDataSourceDetails(
            ticketId,
            dataSource,
            dataSourceType
          )
        ).data
      );
    })();
  }, [ticketId]);

  const setItems: SevTwo[] = dataSourceItems || [];

  const getSev2Items = () => {
    const sev2Items: SevTwo[] =
      setItems[0]["compressedData"][dataSourceType]["items"];
    return sev2Items;
  };

  return (
    <AppLayout
      content={
        <ContentLayout
          header={
            <Header variant="h1">
              {dataSource} {dataSourceType} Insights
            </Header>
          }
        >
          <SpaceBetween size="l">
            {setItems.length > 0 ? (
              <Table
                id="Sev2_Table_Listview"
                columnDefinitions={[
                  {
                    id: "title",
                    header: "Title",
                    cell: (e) => (
                      <div style={{ whiteSpace: "normal" }}>{e["title"]}</div>
                    ),
                  },
                  {
                    id: "impact",
                    header: "Impact",
                    cell: (e) => (
                      <div style={{ whiteSpace: "normal" }}>{e["impact"]}</div>
                    ),
                  },
                  {
                    id: "isHse",
                    header: "HSE",
                    cell: (e) => (
                      <div style={{ whiteSpace: "normal" }}>{e["isHse"]}</div>
                    ),
                  },
                  {
                    id: "serviceName",
                    header: "Service Name",
                    cell: (e) => (
                      <div style={{ whiteSpace: "normal" }}>
                        {e["serviceName"]}
                      </div>
                    ),
                  },
                  {
                    id: "region",
                    header: "Region",
                    cell: (e) => (
                      <div style={{ whiteSpace: "normal" }}>{e["region"]}</div>
                    ),
                  },
                  {
                    id: "category",
                    header: "Category",
                    cell: (e) => e["category"],
                    width: 200,
                  },
                  {
                    id: "type",
                    header: "Type",
                    cell: (e) => e["type"],
                  },
                  {
                    id: "item",
                    header: "Item",
                    cell: (e) => e["item"],
                  },
                  {
                    id: "assignedGroup",
                    header: "Assigned Group",
                    cell: (e) => e["assignedGroup"],
                  },
                  {
                    id: "createdDate",
                    header: "Created Date",
                    cell: (e) => formatDate(Number(e["createDate"])),
                  },
                ]}
                items={getSev2Items()}
                empty={
                  <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                      No Sev2 to display.
                    </Box>
                  </Box>
                }
              />
            ) : (
              <div style={{ margin: "auto", width: "50%" }}>
                <Spinner size="large" />
              </div>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
      navigation={<Navigation />}
    />
  );
}
