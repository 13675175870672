import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Navigation from "../../SideNavigation";
import React from "react";
import SourceTypes from "./SourceTypes";

export default function SourceTypeHome() {
  /*
       Common Component for rendering datasource types (Ex: Apollo Primary Service, Apollo Dependency Service)
     */
  return (
    <AppLayout
      content={
        <ContentLayout>
          <br />
          <SourceTypes />
        </ContentLayout>
      }
      navigation={<Navigation />}
    />
  );
}
