import React, {useState} from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import {Box, Button, Container, Icon, SpaceBetween, Toggle} from "@amzn/awsui-components-react";
import Timeline from "react-vis-timeline-2";
import type {TimelineItem, TimelineOptions} from 'vis-timeline/types';
import {
    APOLLO,
    EventGroup,
    EventGroupType,
    MCM,
    METRIC,
    SEV2,
    TicketDataHelper,
    TimelineEvent,
    WEBLABS
} from "./EventsHelper";
import './EventsTimeline.css';
import {EventsTimelineHelpContent} from "./EventsTimelineHelpContent";
import {DemTeamLink} from "../DemTeamLink";

// Classes for applying aws-ui classes to plain HTML buttons with custom classes.
// AWS-UI button does not allow using custom classes.
const DEFAULT_BUTTON_CLASSES = "awsui_button_vjswe_5vzgt_101 awsui_variant-normal_vjswe_5vzgt_124";

export function EventsTimeline(props) {

    // Receiving method to update toolbox / help bar contents in DashboardHome component.
    const updateToolBoxContent = props.updateToolboxContent;

    /**
     * Enum representing Expand/Collapsed toggle states.
     */
    enum ExpandToggle {
        Expand,
        Collapse,
        Default
    }

    /**
     * Enum representing event types filter properties on timeline.
     */
    interface EventTypesSelected {
        apolloSelected: boolean;
        mcmSelected: boolean;
        weblabSelected: boolean;
        sev2Selected: boolean;
        lseSelected: boolean;
        metricSelected: boolean;
        apolloColor: string;
        mcmColor: string;
        weblabColor: string;
        sev2Color: string;
        metricColor: string;
    }

    const [dependencyGroupsVisible, setDependencyGroupsVisibility] = useState(true);
    const [lseEventsVisibility, setLseEventsVisibility] = useState(true);
    const [expandGroupsToggle, setExpandGroupsToggle] = useState(ExpandToggle.Default);
    const [eventsVisibility, setEventsVisibility] = useState<EventTypesSelected>({
        apolloSelected: false,
        mcmSelected: false,
        weblabSelected: false,
        sev2Selected: false,
        lseSelected: false,
        metricSelected: false,
        apolloColor: "#FFE082",
        mcmColor: "#C5E1A5",
        weblabColor: "#81D4FA",
        sev2Color: "#F48FB1",
        metricColor: "#D1C4E9"
    });
    const [timelineRef, setTimelineRef] = useState<null | Timeline>();

    const allEvents: Object[] = props.ticketItems || [];
    const ticketDataHelper: TicketDataHelper = new TicketDataHelper(allEvents);
    const finalGroups: EventGroup[] = ticketDataHelper.getInitialGroups;

    // Evaluating event types filters selection
    const allEventsSelected = eventsVisibility.apolloSelected
        && eventsVisibility.weblabSelected
        && eventsVisibility.mcmSelected
        && eventsVisibility.sev2Selected
        && eventsVisibility.metricSelected;
    const noEventsSelected = !eventsVisibility.apolloSelected
        && !eventsVisibility.weblabSelected
        && !eventsVisibility.mcmSelected
        && !eventsVisibility.sev2Selected
        && !eventsVisibility.metricSelected;

    // Setting visibility for events depending on filters.
    finalGroups.forEach(group => {
        const mainGroupCondition: boolean =
            group.groupType === EventGroupType.PRIMARY
            || lseEventsVisibility && group.groupType === EventGroupType.LSE
            || dependencyGroupsVisible && group.groupType === EventGroupType.DEPENDENCY;
        const eventsGroupCondition = allEventsSelected || noEventsSelected || !group.eventType
            || eventsVisibility.apolloSelected && group.eventType === APOLLO
            || eventsVisibility.mcmSelected && group.eventType === MCM
            || eventsVisibility.weblabSelected && group.eventType === WEBLABS
            || eventsVisibility.sev2Selected && group.eventType === SEV2
            || eventsVisibility.metricSelected && group.eventType === METRIC;
        group.visible = mainGroupCondition && eventsGroupCondition;
        if (expandGroupsToggle === ExpandToggle.Expand) {
            group.showNested = true;
        }
        if (expandGroupsToggle === ExpandToggle.Collapse) {
            group.showNested = false;
        }
    });

    // Hiding service groups for which no nested group is visible.
    finalGroups.forEach(group => {
        if (group.visible
            && group.nestedGroups?.every(
                groupId => !ticketDataHelper.getGroupById(groupId.toString()).visible
            )) {
            group.visible = false;
        }
    });

    // Adding background tint to service groups for selected events ranges.
    ticketDataHelper.addBackgroundForServiceGroups();

    const finalItems: TimelineItem[] = ticketDataHelper.getInitialItems;

    if (timelineRef) {
        timelineRef.timeline.setGroups(finalGroups);
        timelineRef.timeline.setItems(finalItems);
        if (ticketDataHelper.impactStartTime) {
            try {
                timelineRef.timeline.getCustomTime("Ticket");
            } catch (e) {
                timelineRef.timeline.addCustomTime(
                    new Date(ticketDataHelper.impactStartTime), "Ticket");
            } finally {
                // TS doesn't recognize `setCustomTimeMarker` method
                // because of limited typing support. Ignoring TS here.
                // @ts-ignore
                timelineRef.timeline.setCustomTimeMarker("Impact Start", "Ticket");
            }
        }
    }

    // Setting options for timeline.
    const options: TimelineOptions = {
        stack: true,
        stackSubgroups: true,
        orientation: {
            axis: "both",
            item: "top"
        },
        verticalScroll: true,
        maxHeight: 640,
        min: ticketDataHelper.getStartTime,
        max: ticketDataHelper.getEndTime,
        zoomKey: "ctrlKey",
        horizontalScroll: true,
        tooltip: {
            overflowMethod: "cap"
        }
    };


    /**
     * Functions to handle event click and open respective URL in a new tab.
     * @param event The event object received from click.
     */
    function handleItemClick(event) {
        if (event.item && ticketDataHelper.getEventById(event.item)) {
            const timelineEvent: TimelineEvent = ticketDataHelper.getEventById(event.item);
            window.open(timelineEvent.url, "_blank", "noreferrer");
        }
    }

    /**
     * Function to handle expand and collapse toggle on timeline.
     */
    function handleExpandToggle() {
        if (expandGroupsToggle ===  ExpandToggle.Expand) {
            setExpandGroupsToggle(ExpandToggle.Collapse);
        } else {
            setExpandGroupsToggle(ExpandToggle.Expand);
        }
    }

    /**
     * Method to focus timeline on the visible set of events bringing them in center.
     */
    function focusTimeline() {
        if (timelineRef) {
            let visibleItems: string[] = [];
            finalGroups.forEach(group => {
                if (group.visible && group.eventType) {
                    visibleItems.push(...group.eventIds);
                }
            })
            timelineRef.timeline.focus(visibleItems);
        }
    }

    /**
     * Opening toolbox on Dashboard home to show Info about timeline.
     */
    function openToolbox() {
        updateToolBoxContent(<EventsTimelineHelpContent/>);
    }

    return(
        <Container
            header={
                <Header variant="h2"
                description="The Events Timeline is a snapshot of events captured during the HSE
                or Sev2 event and does not represent the current state of the events.">
                    <SpaceBetween size={"s"} direction={"horizontal"}>
                        <div>
                            Events Timeline
                        </div>
                        <div onClick={openToolbox}>
                            <Icon name="status-info"/>
                        </div>
                    </SpaceBetween>
                </Header>
            }
        >
            { allEvents.length > 0 && finalItems.length > 0 && finalGroups.length > 0 ?
                <div>
                    <div className={"flex-container"}>
                        <div>
                            <SpaceBetween size={"xs"} direction={"vertical"}>
                                <Toggle
                                    onChange={({detail}) =>
                                        setLseEventsVisibility(detail.checked)}
                                    checked={lseEventsVisibility}>
                                    Show Large Scale Events
                                </Toggle>
                                <Toggle
                                    onChange={({detail}) =>
                                        setDependencyGroupsVisibility(detail.checked)}
                                    checked={dependencyGroupsVisible}>
                                    Show Dependency Services
                                </Toggle>
                            </SpaceBetween>
                            <br/>
                            <SpaceBetween size={"xs"} direction={"horizontal"}>
                                <Button
                                    onClick={handleExpandToggle}>
                                    {expandGroupsToggle === ExpandToggle.Expand ?
                                        "Collapse All" : "Expand All"}
                                </Button>
                                <Button
                                    onClick={focusTimeline}>
                                    Focus
                                </Button>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween size={"xs"} direction={"horizontal"}>
                                <button
                                    style={{backgroundColor: eventsVisibility.apolloSelected ?
                                            eventsVisibility.apolloColor : ""}}
                                    onClick={() => setEventsVisibility({
                                        ...eventsVisibility,
                                        apolloSelected: !eventsVisibility.apolloSelected
                                    })}
                                    className={DEFAULT_BUTTON_CLASSES}>
                                    Apollo
                                </button>
                                <button
                                    style={{backgroundColor: eventsVisibility.mcmSelected ?
                                            eventsVisibility.mcmColor: ""}}
                                    onClick={() => setEventsVisibility({
                                        ...eventsVisibility,
                                        mcmSelected: !eventsVisibility.mcmSelected
                                    })}
                                    className={DEFAULT_BUTTON_CLASSES}>
                                    MCM
                                </button>
                                <button
                                    style={{backgroundColor: eventsVisibility.weblabSelected ?
                                            eventsVisibility.weblabColor : ""}}
                                    onClick={() => setEventsVisibility({
                                        ...eventsVisibility,
                                        weblabSelected: !eventsVisibility.weblabSelected
                                    })}
                                    className={DEFAULT_BUTTON_CLASSES}>
                                    Weblab
                                </button>
                                <button
                                    style={{backgroundColor: eventsVisibility.sev2Selected ?
                                            eventsVisibility.sev2Color : ""}}
                                    onClick={() => setEventsVisibility({
                                        ...eventsVisibility,
                                        sev2Selected: !eventsVisibility.sev2Selected
                                    })}
                                    className={DEFAULT_BUTTON_CLASSES}>
                                    Sev2
                                </button>
                                <button
                                    style={{backgroundColor: eventsVisibility.metricSelected ?
                                            eventsVisibility.metricColor : ""}}
                                    onClick={() => setEventsVisibility({
                                        ...eventsVisibility,
                                        metricSelected: !eventsVisibility.metricSelected
                                    })}
                                    className={DEFAULT_BUTTON_CLASSES}>
                                    Metric
                                </button>
                            </SpaceBetween>
                        </div>
                    </div>
                    <br/>
                    <div>
                        <Timeline
                            ref={setTimelineRef}
                            initialGroups={finalGroups}
                            initialItems={finalItems}
                            options={options}
                            clickHandler={handleItemClick}
                        />
                    </div>
                </div>
                :
                <div>
                    {
                        // If there are no events to display, and we fail to transform events,
                        // Request users to report the issue to the team.
                        ticketDataHelper.failedToTransformEvents.length > 0 ?
                            <Box color={"text-status-error"}>
                                Failed to process events for timeline. Please report this issue to
                                 the <DemTeamLink/>.
                            </Box> :
                            <Box>
                                No events to Display.
                            </Box>
                    }
                </div>
            }
        </Container>
    );
}

export default EventsTimeline;
