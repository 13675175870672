import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import { Container } from "@amzn/awsui-components-react";
import { Ticket } from "../../tickets-api/generated-src";
import Table from "@amzn/awsui-components-react/polaris/table";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

export default function AnomalyGraph(props) {
  /*
       Component to render anomaly graphs
   */

  const allSourceItems: Ticket[] = props.data;

  const graphLinksArray = allSourceItems.filter((item) => {
    /*
        Filtering the raw datasources array for having only metric datasources
       */
    return "igraph_metric_name" in item;
  });

  return (
    <>
      <SpaceBetween size="l">
        {graphLinksArray.length > 0 ? (
          <Container header={<Header variant="h2">Graphed Anomalies</Header>}>
            <Table
              id="GraphedAnomaliesTable"
              columnDefinitions={[
                {
                  id: "serviceName",
                  header: "Service Name",
                  cell: (e) => e.primaryServiceName,
                },
                {
                  id: "metricName",
                  header: "Metric",
                  cell: (e) => e.igraph_metric_name,
                },
                {
                  id: "igraphImage",
                  header: "Igraph",
                  cell: (e) => (
                    <a target="_blank" href={e.igraphLink}>
                      <img
                        alt="graph anomalies"
                        height="255px"
                        width="340px"
                        src={e.igraphLink}
                      />
                    </a>
                  ),
                },
              ]}
              items={graphLinksArray}
            />
          </Container>
        ) : (
          <></>
        )}
      </SpaceBetween>
    </>
  );
}
