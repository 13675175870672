import { useHistory, useParams } from "react-router-dom";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import React from "react";

export default function Navigation() {
  /*
       Component to render Side Navigation Panel
   */
  const { ticketId } = useParams<{ ticketId: string }>();
  const { dataSource } = useParams<{ dataSource: string }>();

  const [activeHref, setActiveHref] = React.useState("/");
  const nav_items: any[] = [
    { type: "link", text: "Dashboard", href: `/${ticketId}` },
    { type: "link", text: "Insights", href: `/${ticketId}/${dataSource}` },
  ];

  const checkUrlParams = (urlPath: string) => {
    const paramsList = urlPath.split("/");

    if (paramsList.includes("undefined")) {
      return false;
    }
    return true;
  };

  const history = useHistory();
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: "/", text: "DEM Insights" }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();

          if (checkUrlParams(event.detail.href)) {
            setActiveHref(event.detail.href);
            history.push(event.detail.href);
          }
        }
      }}
      items={nav_items}
    />
  );
}
