import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Navigation from "../SideNavigation";
import { useEffect, useState } from "react";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import { useParams } from "react-router-dom";
import { Lse } from "../../tickets-api/generated-src";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { Box } from "@amzn/awsui-components-react";
import { formatDate } from "../commons/OngoingInsights";

export default function LseEvents() {
  /*
       Component to display LSE
   */
  const [dataSourceItems, setDataSourceItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { dataSource } = useParams<{ dataSource: string }>();
  let { dataSourceType } = useParams<{ dataSourceType: string }>();

  if (dataSourceType == undefined) {
    dataSourceType = "Lse";
  }

  useEffect(() => {
    (async () => {
      setDataSourceItems(
        (
          await TicketsApi.showDataSourceDetails(
            ticketId,
            dataSource,
            dataSourceType
          )
        ).data
      );
    })();
  }, [ticketId]);

  const setItems: Lse[] = dataSourceItems || [];

  const getLseItems = () => {
    const LseItems: Lse[] = setItems[0]["compressedData"][dataSource]["items"];
    return LseItems;
  };

  return (
    <AppLayout
      content={
        <ContentLayout
          header={<Header variant="h1">{dataSource} Insights </Header>}
        >
          <SpaceBetween size="l">
            {setItems.length > 0 ? (
              <Table
                id="Lse_Table_Listview"
                columnDefinitions={[
                  {
                    id: "category",
                    header: "Category",
                    cell: (e) => e["properties"]["Symptoms"],
                  },
                  {
                    id: "start",
                    header: "Start",
                    cell: (e) => formatDate(Number(e.start)),
                  },
                  {
                    id: "end",
                    header: "End",
                    cell: (e) => formatDate(Number(e.end)),
                  },
                  {
                    id: "status",
                    header: "Status",
                    cell: (e) => e["properties"]["Status"],
                  },
                  {
                    id: "description",
                    header: "Description",
                    cell: (e) => e["properties"]["Description"],
                    width: 200,
                  },
                  {
                    id: "link",
                    header: "Link",
                    cell: (e) => (
                      <a target="_blank" href={`${e["properties"]["Link"]}`}>
                        Link
                      </a>
                    ),
                  },
                  {
                    id: "impact",
                    header: "Impact",
                    cell: (e) => e["properties"]["Impact"],
                  },
                ]}
                items={getLseItems()}
                empty={
                  <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                      No Lse events to display.
                    </Box>
                  </Box>
                }
              />
            ) : (
              <div style={{ margin: "auto", width: "50%" }}>
                <Spinner size="large" />
              </div>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
      navigation={<Navigation />}
    />
  );
}
