import React from "react";
import {
  Button,
  ButtonDropdown,
  Input,
  Toggle,
} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import "./MitigationLeverStyles.scss";

/**
 * Component to render Mitigation Levers
 */
export default function MitigationLevers() {
  const [throttlingChecked, setThrottlingChecked] = React.useState(false);
  const [autoScalingChecked, setAutoScalingChecked] = React.useState(false);
  const [rollbackDeploymentsChecked, setRollbackDeploymentsChecked] =
    React.useState(false);

  const [throttlingLimit, setThrottlingLimit] = React.useState("");
  const [rollbackReason, setRollbackReason] = React.useState("");
  const [approver, setApprover] = React.useState("");

  const [submitThrottlingClicked, setThrottlingSubmitClicked] =
    React.useState(false);
  const [submitAutoScaledClicked, setAutoScaledSubmitClicked] =
    React.useState(false);
  const [submitRollbackDeploymentClicked, setRollbackDeploymentSubmitClicked] =
    React.useState(false);

  /**
   * Component for showing success message on clicking Submit
   */
  const submitSuccess = () => {
    return (
      <div id="sucessDiv">
        <Icon name="status-positive" variant="success" />
        <span id="successText">Request submitted</span>
      </div>
    );
  };

  /**
   * Modal pop-up for rendering Forms
   */
  const Modal = (props) => {
    return (
      <>
        <div id="popupBox">
          <div id="box">{props.content}</div>
          <span id="closeIcon" onClick={props.handleClose}>
            x
          </span>
        </div>
      </>
    );
  };

  /**
   * Input form for Throttling
   */
  const throttlingContent = () => {
    return (
      <div>
        <Header>Throttling</Header>
        <hr id="horizontalLine" />
        <div id="textDescription">
          Requests being throttled from Customer ID{" "}
        </div>
        <div>A2Y92PCUL5NYE9</div>
        <div id="textDescription">
          Current throttling value from AlexaCapabilitiesRuntimeService
        </div>
        <div>250</div>
        <div id="textDescription">
          Update throttling config value <span id="asterick">*</span>
        </div>
        <Input
          onChange={({ detail }) => setThrottlingLimit(detail.value)}
          value={throttlingLimit}
          placeholder="5"
          type="number"
        />
        <div id="textDescription">
          Approver <span id="asterick">*</span>
        </div>
        <Input
          onChange={({ detail }) => setApprover(detail.value)}
          value={approver}
          type="text"
        />
        <br />
        <span>
          <Button
            variant="primary"
            onClick={() => {
              setThrottlingSubmitClicked(!submitThrottlingClicked);
            }}
          >
            Submit
          </Button>
          {submitThrottlingClicked ? submitSuccess() : <></>}
        </span>
      </div>
    );
  };

  /**
   * Inout form for Auto-Scaling
   */
  const autoScalingContent = () => {
    return (
      <div>
        <Header>Scale Infrastructure</Header>
        <hr id="horizontalLine" />
        <div id="textDescription">Current infrastructure</div>
        <div>m4.xlarge</div>
        <div id="textDescription">
          Recommended host <span id="asterick">*</span>
        </div>
        <ButtonDropdown
          items={[
            { text: "m5.xlarge", id: "id1" },
            {
              text: "r5.large",
              id: "id2",
            },
          ]}
        >
          Host types
        </ButtonDropdown>
        <div id="textDescription">
          Approver <span id="asterick">*</span>
        </div>
        <Input
          onChange={({ detail }) => setApprover(detail.value)}
          value={approver}
          type="text"
        />
        <br />
        <span>
          <Button
            variant="primary"
            onClick={() => {
              setAutoScaledSubmitClicked(!submitAutoScaledClicked);
            }}
          >
            Submit
          </Button>
          {submitAutoScaledClicked ? submitSuccess() : <></>}
        </span>
      </div>
    );
  };

  /**
   * Input form for Rollback Deployments
   */

  const rollbackContent = () => {
    return (
      <div>
        <Header>Rollback Deployments</Header>
        <hr id="horizontalLine" />
        <div id="textDescription">
          Select deployment to rollback <span id="asterick">*</span>
        </div>
        <ButtonDropdown
          items={[
            {
              text: "AlexaCapabilitiesRuntimeService/tpstesting@B6120212718-AL2_x86_64",
              id: "id1",
            },
            {
              text: "AlexaCapabilitiesRuntimeService/FE@B8125958452-AL2_x86_64",
              id: "id2",
            },
            {
              text: "AlexaCapabilitiesRuntimeService/EU@B6125958452-AL2_x86_64",
              id: "id3",
            },
            {
              text: "AlexaCapabilitiesRuntimeService/NA@B6125958452-AL2_x86_64",
              id: "id4",
            },
            {
              text: "AlexaCapabilitiesRuntimeService/NA@AX162Y38U2J-AL2_x86_64",
              id: "id5",
            },
          ]}
        >
          Last 5 Deployments
        </ButtonDropdown>
        <div id="textDescription">
          Reason for rollback <span id="asterick">*</span>
        </div>
        <Input
          onChange={({ detail }) => setRollbackReason(detail.value)}
          value={rollbackReason}
          type="text"
        />
        <div id="textDescription">
          Approver <span id="asterick">*</span>
        </div>
        <Input
          onChange={({ detail }) => setApprover(detail.value)}
          value={approver}
          type="text"
        />
        <br />
        <span>
          <Button
            variant="primary"
            onClick={() => {
              setRollbackDeploymentSubmitClicked(
                !submitRollbackDeploymentClicked
              );
            }}
          >
            Submit
          </Button>
          {submitRollbackDeploymentClicked ? submitSuccess() : <></>}
        </span>
      </div>
    );
  };

  /**
   * Re-sets all values on closing Throttling modal
   */
  const closeThrottlingModal = () => {
    setThrottlingChecked(!throttlingChecked);
    setThrottlingSubmitClicked(!submitThrottlingClicked);
    setApprover("");
  };

  /**
   * Re-sets all values closing Auto-scaling modal
   */
  const closeAutoScalingModal = () => {
    setAutoScalingChecked(!autoScalingChecked);
    setAutoScaledSubmitClicked(!submitAutoScaledClicked);
    setApprover("");
  };

  /**
   * Re-sets all values closing Rollback deployments modal
   */
  const closeRollbackModal = () => {
    setRollbackDeploymentsChecked(!rollbackDeploymentsChecked);
    setRollbackDeploymentSubmitClicked(!submitRollbackDeploymentClicked);
    setApprover("");
  };

  /**
   * Renders modal pop-up based on Mitigation lever selected
   */
  const toggleAction = (mitigationLever: string) => {
    switch (mitigationLever) {
      case "Throttling":
        return (
          <Modal
            content={throttlingContent()}
            handleClose={closeThrottlingModal}
          />
        );
      case "AutoScaling":
        return (
          <Modal
            content={autoScalingContent()}
            handleClose={closeAutoScalingModal}
          />
        );
      case "RollbackDeployments":
        return (
          <Modal content={rollbackContent()} handleClose={closeRollbackModal} />
        );
    }
  };

  return (
    <>
      <Toggle
        onChange={({ detail }) => setThrottlingChecked(detail.checked)}
        checked={throttlingChecked}
      >
        Adjust Throttling
      </Toggle>
      <Toggle
        onChange={({ detail }) => setAutoScalingChecked(!autoScalingChecked)}
        checked={autoScalingChecked}
      >
        Scale Infrastructure
      </Toggle>

      <Toggle
        onChange={({ detail }) =>
          setRollbackDeploymentsChecked(!rollbackDeploymentsChecked)
        }
        checked={rollbackDeploymentsChecked}
      >
        Rollback Deployments
      </Toggle>
      <br />
      <div>{throttlingChecked ? toggleAction("Throttling") : <></>}</div>
      <div>{autoScalingChecked ? toggleAction("AutoScaling") : <></>}</div>
      <div>
        {rollbackDeploymentsChecked ? (
          toggleAction("RollbackDeployments")
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
