import { Badge, Container } from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import React from "react";
import { Workflow } from "../../tickets-api/generated-src";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

export default function WorkflowsExecuted(props) {
  let allWorkflows: Workflow[] = props.data || [];
  let workflows: string[] = [];
  allWorkflows.forEach((workflow) => {
    if (workflow.IsWorkflowActive) {
      let workflowName = workflow.WorkflowName.split("#")[0]
        .replace("DWF_", "")
        .replace("_", " ");
      workflows.push(workflowName);
    }
  });
  const handleEvents = (allActiveWorkflowNames) => {
    if (allActiveWorkflowNames.length > 0) {
      return allActiveWorkflowNames.map((workflowName) => (
        <Badge color="green">{workflowName}</Badge>
      ));
    }
    return (
      <div style={{ margin: "auto" }}>
        <Spinner size="normal" />
      </div>
    );
  };
  return (
    <>
      <Container
        header={
          <Header
            variant="h2"
            description="Below mentioned are the workflows executed for aforementioned ticket Id"
          >
            Workflows Executed
          </Header>
        }
      >
        {handleEvents(workflows)}
      </Container>
    </>
  );
}
