import * as React from "react";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Ticket } from "../../../tickets-api/generated-src";
import TicketsApiFactory from "../../../tickets-api/TicketsApiFactory";
import { Header } from "@amzn/awsui-components-react";
import {
  ongoingProactiveInsights,
  ongoingReactiveInsights,
} from "../../commons/OngoingInsights";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

export default function SourceTypes() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [datasourceItems, setDataSourceItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { dataSource } = useParams<{ dataSource: string }>();

  useEffect(() => {
    void (async () => {
      setDataSourceItems((await TicketsApi.showTicketById(ticketId)).data);
    })();
  }, [ticketId]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const setItems: Ticket[] = datasourceItems || [];

  const SOURCETYPE_ITEMS = ["Primary Services", "Dependency Services"];

  let proactiveInsights = {};

  setItems.forEach(() => {
    proactiveInsights["Primary Services"] = ongoingProactiveInsights(
      dataSource,
      "Primary Services",
      setItems
    );
    proactiveInsights["Dependency Services"] = ongoingProactiveInsights(
      dataSource,
      "Dependency Services",
      setItems
    );
  });

  return (
    <div>
      <Header variant="h1">{dataSource} Source Types</Header>
      {setItems.length > 0 ? (
        <Cards
          cardDefinition={{
            header: (e) => (
              <Link
                style={{ textDecoration: "none" }}
                to={`/${ticketId}/${dataSource}/${e}`}
              >
                <span style={{ fontWeight: "bolder" }}>
                  {proactiveInsights[e] == 0 ? (
                    <span style={{ color: "green" }}>
                      {dataSource} {e}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>
                      {dataSource} {e}
                    </span>
                  )}
                  <span style={{ float: "right" }}>
                    {proactiveInsights[e] == 0 ? (
                      <Icon
                        name="status-positive"
                        size="small"
                        variant="success"
                      />
                    ) : (
                      <Icon
                        name="status-warning"
                        size="small"
                        variant="warning"
                      />
                    )}
                  </span>
                </span>
              </Link>
            ),

            sections: [
              {
                id: "proactiveInsights",
                header: "Ongoing proactive insights",
                content: (e) => (
                  <span style={{ color: "#2565AE" }}>
                    {proactiveInsights[e]}
                  </span>
                ),
              },
              {
                id: "reactiveInsights",
                header: "Ongoing reactive insights",
                content: (e) => (
                  <span style={{ color: "#2565AE" }}>
                    {ongoingReactiveInsights(dataSource, e, setItems)}
                  </span>
                ),
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 400, cards: 3 }]}
          items={SOURCETYPE_ITEMS}
          loadingText="Loading resources"
        />
      ) : (
        <span style={{ marginLeft: "auto", marginRight: "auto" }}>
          <Spinner size="large" />
        </span>
      )}
    </div>
  );
}
